import React from "react"
import Layout from "../components/layout"
import config from "../utils/siteConfig"
import Button from "../components/Atoms/Button/Button"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight } from "@fortawesome/free-solid-svg-icons"

export default function Confirmation({ data }) {
  debugger;
  const page = data.allContentfulZConfirmation.edges[0].node

  const {
    id,
    metaData,
    ccfHd,
    ccfTxt1,
    ccfBtn,
  } = page

  return (
    <Layout
      IsHeaderImageDark={false}
      navColour={"white"}>
      <head>
        <title>{metaData.title}</title>
        <meta charset="utf-8" />
        <meta name="robots" content="noindex" />
      </head>

      <section className="bg-white">
        <div className="thankyou-container">
          <div className="container bg-white">
            <div className="row">
              <div className="col-12">
                <div className="text-center">
                  <h2 className="align-top h2-huge h2-drakgray">
                    {ccfHd}
                  </h2>
                  <div className="row justify-content-center">
                    <h2 className="text-center align-top h1-medium2LightS text-blue2 pt-3">
                      {ccfTxt1.ccfTxt1}
                    </h2>
                  </div>
                  <div className="text-center pt-4">
                    <Button
                      className='btns'
                      buttonStyle='btn--primaryPurple2'
                      buttonSize='btn--txt2'
                      href={config.navigationURL_home}
                    >
                      {ccfBtn}
                      <FontAwesomeIcon className="" size="1x" icon={faChevronRight} className="faChevronRight" />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}



export const query = graphql`
  query($locale: String!) {
    allContentfulZConfirmation (
      filter: {
        node_locale: {eq: $locale},
        id: {in:["6c79cb6e-e173-5b99-8f4b-002c04fc996d", "93faddb0-2c57-5b53-8d64-1591c7fead99"]}
      }) {
      edges {
        node {
          id
          metaData {
            slug
            title
          }
          ccfHd
          ccfTxt1 {
            ccfTxt1
          }
          ccfBtn
        }
      }
    }
  }
`
